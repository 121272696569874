<template>
    <v-row justify="center">
        <v-col cols="12">
            <page-toolbar previous-page-label="HITs List" title="Create HITs">
            </page-toolbar>
        </v-col>
        <v-col cols="10">
            <hit-type-form-card :client="client" @update="updateHITTypeParams" @validate="(_valid) => { valid.HITType = _valid }" />
        </v-col>
        <v-col cols="10">
            <hit-form-card
                @update="updateHITParams"
                @validate="(_valid) => { valid.HIT = _valid }"
                :client="client"
                />
        </v-col>
        <v-col v-if="projectHasDiff" cols="10">
            <v-alert
                border="left"
                type="error">
                Seems like your project has been updated since last build.
                &nbsp;&nbsp;&nbsp;
                <v-btn
                    color="white"
                    outlined
                    :loading="rebuildingProject"
                    @click="rebuildProject">
                    Rebuild
                </v-btn>
            </v-alert>
        </v-col>
        <v-col v-else cols="10" class="text-right">
            <post-hits-button
                :client="client"
                :credentials="credentials"
                :hitTypeInputs="hitTypeInputs"
                :hitInputs="hitInputs"
                :disabled="!(valid.HITType && valid.HIT)"
                />
        </v-col>
    </v-row>
</template>
<script>
import HITTypeFormCard from './HITTypeFormCard'
import HITFormCard from './HITFormCard'
import PostHITsButton from './PostHITsButton'
import PageToolbar from '@/components/ui/PageToolbar'

export default {
    name: "HIT-Create",
    props: ['client', 'credentials'],
    components: {
        PageToolbar,
        "hit-type-form-card": HITTypeFormCard,
        "hit-form-card": HITFormCard,
        "post-hits-button": PostHITsButton,
    },
    data: () => ({
        projectHasDiff: false,
        rebuildingProject: false,

        valid: {
            HITType: false,
            HIT: false,
        },

        tuttiHITBatchName: '',
        hitTypeInputs: null,
        hitInputs: null,
        numCreateHITs: 1,
        selectedHITTypeId: "",
    }),
    methods: {
        updateHITTypeParams(inputs) {
            this.hitTypeInputs = inputs;
        },
        updateHITParams(inputs) {
            this.hitInputs = inputs;
        }
    },
};
</script>
<style scoped>
.input-native {
    border: 1px solid #bbb;
    padding: 0 5px;
    appearance: auto;
}
</style>
