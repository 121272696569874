<template>
    <v-text-field
        dense
        hide-details
        v-bind="$attrs"
        v-model="inputValue"
    >
        <template v-slot:append>
            <v-icon v-if="$attrs.rules.every((r) => (r(value)===true))" color="success">mdi-check</v-icon>
        </template>
    </v-text-field>
</template>
<script>
export default {
    inheritAttrs: false,
    props: ['value'],
    computed: {
        inputValue: {
            get() {
                return this.value;
            },
            set(newValue) {
                this.$emit('input', newValue);
            }
        }
    }
}
</script>
