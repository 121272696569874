<template>
    <div>
        <v-btn
            :dark="!disabled"
            :loading="loading"
            :disabled="disabled"
            color="indigo"
            @click.stop="onButtonClick">
            Post HITs
        </v-btn>

        <v-dialog persistent max-width="800" v-model="dialog" v-if="dialog">
            <v-card>
                <v-card-title v-if="!creating"> Post HITs in Production mode?  </v-card-title>
                <v-card-title v-else>HITs Creation In Progress</v-card-title>
                <v-card-text>
                    <div v-if="!creating">
                        Creating <b>{{ hitInputs.numCreateHITs }}</b> HITs for the project "<b>{{ hitTypeInputs.projectName }}</b>". Are you sure to proceed?<br><br>
                        <p v-if="credentials && !credentials.is_sandbox">
                            <v-icon left color="warning">mdi-alert</v-icon><span class="error--text font-weight-medium text-decoration-underline">You are currently in production mode, which will hire MTurk workers and pay them real money.</span>
                        </p>
                    </div>
                    <div v-else>
                        <v-progress-linear striped :value="creationCounts.percentage" height="20" color="indigo lighten-3">
                            <template v-slot:default="{ value }">
                                <strong>{{ parseInt(value) }}% ({{ creationCounts.success+creationCounts.error }}/{{ creationCounts.all }})</strong>
                            </template>
                        </v-progress-linear>
                        <div class="text-center">
                            Success: {{ creationCounts.success }} , Error: {{ creationCounts.error }}
                        </div>
                    </div>
                </v-card-text>
                <tutti-dialog-actions
                    v-if="!creating"
                    :actions="[
                        {
                            label: 'Cancel',
                            color: 'grey darken-1',
                            text: true,
                            callback: () => { dialog = false; loading = false; }
                        },
                        {
                            label: 'Proceed',
                            color: 'indigo darken-1',
                            dark: true,
                            callback: postHITs
                        },
                        ]" />
                <tutti-dialog-actions
                    v-else
                    :actions="[
                        {
                            label: 'Close',
                            color: 'grey darken-1',
                            text: true,
                            callback: () => { dialog = false; loading = false; }
                        },
                        ]" />
            </v-card>
        </v-dialog>
        <tutti-snackbar ref="snackbar" />
    </div>
</template>

<script>
import TuttiDialogActions from '@/components/ui/TuttiDialogActions'
import TuttiSnackbar from '@/components/ui/TuttiSnackbar'

export default {
    components: {
        TuttiDialogActions,
        TuttiSnackbar,
    },
    data: () => ({
        dialog: false,
        loading: false,
        creating: false,
        creationCounts: {
            success: 0,
            error: 0,
            all: 0,
            percentage: 0
        },
    }),
    props: [
        'client',
        'credentials',
        'disabled',

        'hitTypeInputs',
        'hitInputs',
    ],
    methods: {
        async onButtonClick() {
            this.dialog = true;
        },
        async postHITs() {
            this.loading = true;
            try {
                if(this.hitTypeInputs.createNew) {
                    let qrs = this.hitTypeInputs.hitTypeParams.QualificationRequirements;
                    if(qrs && qrs.length > 0){
                        qrs.forEach((qr,i) => { qrs[i].Integervalues = qr.IntegerValues.map(v => parseInt(v)); });
                    } else {
                        delete this.hitTypeInputs.hitTypeParams.QualificationRequirements;
                    }
                    Object.keys(this.hitTypeInputs.hitTypeParams).forEach(key => {
                        if(['id','_count','_created_at'].includes(key))
                            delete this.hitTypeInputs.hitTypeParams[key];
                    });
                    this.client.mturk.createTuttiHITBatch.send({
                        name: this.hitTypeInputs.tuttiHITBatchName,
                        project_name: this.hitTypeInputs.projectName,
                        hit_type_params: this.hitTypeInputs.hitTypeParams,
                        hit_params: this.hitInputs.hitParams,
                        num_hits: this.hitInputs.numCreateHITs,
                    });
                } else {
                    this.client.mturk.addHITsToTuttiHITBatch.send({
                        batch_id: this.hitTypeInputs.selectedHITBatch.id,
                        hit_params: this.hitInputs.hitParams,
                        num_hits: this.hitInputs.numCreateHITs,
                    });
                }
                this.creationCounts = {
                        success: 0,
                        error: 0,
                        all: this.hitInputs.numCreateHITs,
                        percentage: 0
                    },
                this.creating = true;
            } catch (e) {
                console.error(e);
            }
        },
    },
    mounted() {
        this.client.invokeOnOpen(async () => {
            this.client.mturk.on(['createTuttiHITBatch', 'addHITsToTuttiHITBatch'], {
                success: (data) => {
                    if(data) {
                        this.creationCounts = {
                            ...data.counts,
                            percentage: (data.counts.success+data.counts.error)/data.counts.all*100,
                        }
                    } else {
                        this.loading = false;
                        this.$refs.snackbar.show('success', 'Successfully posted HITs');
                    }
                },
                error: (data) => {
                    this.$refs.snackbar.show('error', 'Error in posting HITs');
                    console.error(data);
                }
            });
        });
    }
}
</script>
