var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-btn',{attrs:{"dark":!_vm.disabled,"loading":_vm.loading,"disabled":_vm.disabled,"color":"indigo"},on:{"click":function($event){$event.stopPropagation();return _vm.onButtonClick.apply(null, arguments)}}},[_vm._v(" Post HITs ")]),(_vm.dialog)?_c('v-dialog',{attrs:{"persistent":"","max-width":"800"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[(!_vm.creating)?_c('v-card-title',[_vm._v(" Post HITs in Production mode? ")]):_c('v-card-title',[_vm._v("HITs Creation In Progress")]),_c('v-card-text',[(!_vm.creating)?_c('div',[_vm._v(" Creating "),_c('b',[_vm._v(_vm._s(_vm.hitInputs.numCreateHITs))]),_vm._v(" HITs for the project \""),_c('b',[_vm._v(_vm._s(_vm.hitTypeInputs.projectName))]),_vm._v("\". Are you sure to proceed?"),_c('br'),_c('br'),(_vm.credentials && !_vm.credentials.is_sandbox)?_c('p',[_c('v-icon',{attrs:{"left":"","color":"warning"}},[_vm._v("mdi-alert")]),_c('span',{staticClass:"error--text font-weight-medium text-decoration-underline"},[_vm._v("You are currently in production mode, which will hire MTurk workers and pay them real money.")])],1):_vm._e()]):_c('div',[_c('v-progress-linear',{attrs:{"striped":"","value":_vm.creationCounts.percentage,"height":"20","color":"indigo lighten-3"},scopedSlots:_vm._u([{key:"default",fn:function({ value }){return [_c('strong',[_vm._v(_vm._s(parseInt(value))+"% ("+_vm._s(_vm.creationCounts.success+_vm.creationCounts.error)+"/"+_vm._s(_vm.creationCounts.all)+")")])]}}],null,false,3352934868)}),_c('div',{staticClass:"text-center"},[_vm._v(" Success: "+_vm._s(_vm.creationCounts.success)+" , Error: "+_vm._s(_vm.creationCounts.error)+" ")])],1)]),(!_vm.creating)?_c('tutti-dialog-actions',{attrs:{"actions":[
                    {
                        label: 'Cancel',
                        color: 'grey darken-1',
                        text: true,
                        callback: () => { _vm.dialog = false; _vm.loading = false; }
                    },
                    {
                        label: 'Proceed',
                        color: 'indigo darken-1',
                        dark: true,
                        callback: _vm.postHITs
                    },
                    ]}}):_c('tutti-dialog-actions',{attrs:{"actions":[
                    {
                        label: 'Close',
                        color: 'grey darken-1',
                        text: true,
                        callback: () => { _vm.dialog = false; _vm.loading = false; }
                    },
                    ]}})],1)],1):_vm._e(),_c('tutti-snackbar',{ref:"snackbar"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }